import { FC, useCallback, useEffect, useState } from 'react';
import { deserializeFiles, serializeFiles } from '../../lib';
import { UploadedFile, UploadFieldAttrs } from '../../types';
import DropArea from './DropArea';
import UploadingFile from './UploadingFile';
import FileTile from './FileTile';
import LayoutSwitcher from './LayoutSwitcher';
import cn from 'classnames';

const MAX_FILE_SIZE = 100 * 1024 * 1024;

interface AttrChanges {
  (attr: 'serializedFiles', value: string): void;
  (attr: 'layout', value: 'list' | 'grid'): void;
}

const FileUpload: FC<UploadFieldAttrs & { onAttrChanges: AttrChanges }> = ({
  layout = 'list',
  onAttrChanges,
  pageId,
  serializedFiles,
  teamId,
}) => {
  const [fileList, setFileList] = useState(deserializeFiles(serializedFiles));
  const [currentLayout, setCurrentLayout] = useState(layout);
  const [uploadingFiles, setUploadingFiles] = useState<File[]>([]);
  const [lastUploadError, setLastUploadError] = useState<Error | null>(null);

  useEffect(() => {
    if (lastUploadError) {
      setTimeout(() => setLastUploadError(null), 3000);
    }
  }, [lastUploadError]);

  useEffect(() => {
    onAttrChanges('serializedFiles', serializeFiles(fileList));
  }, [fileList, onAttrChanges]);

  useEffect(() => {
    onAttrChanges('layout', currentLayout);
  }, [currentLayout, onAttrChanges]);

  const onUploaded = useCallback((file: File, uploadedFile: UploadedFile) => {
    setUploadingFiles((prev) => prev.filter((f) => f !== file));
    setFileList((prev) => [...prev, uploadedFile]);
  }, []);

  const onDrop = useCallback((files: File[]) => {
    if (files.find((file) => file.size > MAX_FILE_SIZE)) {
      setLastUploadError(new Error('The file exceeds the maximum size limit.'));
      return;
    }

    setUploadingFiles((prev) => Array.from(new Set([...prev, ...files])));
  }, []);

  const onDelete = (file: UploadedFile) => () => {
    setFileList((prev) => prev.filter((f) => f !== file));
  };

  return (
    <div className="select-none py-5">
      <DropArea
        error={lastUploadError?.message}
        onDrop={onDrop}
        maxSizeInBytes={MAX_FILE_SIZE}
      />
      {fileList.length + uploadingFiles.length > 0 && (
        <div className="flex flex-row flex-nowrap items-center justify-between mt-6 mb-4 text-2xl font-bold text-gray-900 w-full">
          <span>Shared files</span>
          <LayoutSwitcher layout={currentLayout} onSwitch={setCurrentLayout} />
        </div>
      )}
      <div
        className={cn({
          'grid grid-cols-2 gap-5': currentLayout === 'grid',
          'flex flex-col gap-3 items-stretch': currentLayout === 'list',
        })}
      >
        {fileList.map((file) => (
          <FileTile
            name={file.name}
            size={file.size}
            type={file.mime}
            key={file.id}
            layout={currentLayout}
            url={file.url}
            onDelete={onDelete(file)}
          />
        ))}
        {uploadingFiles.map((file) => (
          <UploadingFile
            file={file}
            key={file.name}
            layout={currentLayout}
            onError={setLastUploadError}
            onUploaded={onUploaded}
            pageId={pageId}
            teamId={teamId}
          />
        ))}
      </div>
    </div>
  );
};

export default FileUpload;
