import { FC, useEffect, useState } from 'react';
import { uploadFile } from '../../lib/uploader';
import { UploadedFile } from '../../types';
import { AxiosProgressEvent } from 'axios';
import FileTile from './FileTile';

interface Props {
  file: File;
  layout: 'list' | 'grid';
  onError(error: Error): void;
  onUploaded(file: File, uploadedFile: UploadedFile): void;
  pageId: string;
  teamId: number;
}

const UploadingFile: FC<Props> = ({
  file,
  layout,
  onError,
  onUploaded,
  pageId,
  teamId,
}) => {
  const [progress, setProgress] = useState<number>(0);
  const [dev_IsUploaded, setIsUploaded] = useState<boolean>(false);

  useEffect(() => {
    uploadFile(pageId, teamId, file, (event: AxiosProgressEvent) => {
      if (!event.total) {
        setProgress(0);
      } else {
        setProgress(event.loaded / event.total);
      }
    })
      .then((fileUrl) => {
        const url = fileUrl.split('/');
        const fileName = url[url.length - 1];
        const id = fileName.split('.')[0];

        onUploaded(file, {
          id: id,
          mime: file.type,
          name: file.name,
          preview: fileUrl,
          size: file.size,
          url: fileUrl,
        });
        setIsUploaded(true);
      })
      .catch(onError);
  }, [file, onError, onUploaded, pageId, teamId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (dev_IsUploaded) {
        setProgress((prev) => {
          const newVal = prev + Math.random() / 100;

          if (newVal >= 1) {
            return 0;
          }

          return newVal;
        });
      }
    }, 100);

    return () => clearInterval(interval);
  }, [dev_IsUploaded]);

  return (
    <FileTile
      layout={layout}
      name={file.name}
      size={file.size}
      type={file.type}
      progress={progress}
    />
  );
};

export default UploadingFile;
