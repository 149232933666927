import { FC, useState } from 'react';
import {
  bytesToHumanReadable,
  getExtension,
  removeUnnecessaryZeros,
} from '../../lib/parsers';
import { DownloadIcon, EyeIcon, FileIcon, TrashIcon } from '../../icons';
import cn from 'classnames';
import Dropdown from './Dropdown';
import { Modal } from './Modal';

interface Props {
  layout: 'list' | 'grid';
  name: string;
  size: number;
  type: string;
  progress?: number;
  url?: string;
  onDelete?(): void;
}

const FileTile: FC<Props> = ({
  layout,
  name,
  size,
  type,
  progress,
  url,
  onDelete,
}) => {
  const [isDeleteModalOpen, setDeleteModalOpenState] = useState(false);

  return (
    <div className="cursor-pointer">
      <div
        className={cn(
          'flex justify-center items-center border-t border-r border-l border-gray-200 rounded-t-xl bg-gray-50 h-36 bg-cover bg-center bg-no-repeat',
          {
            hidden: layout === 'list',
          }
        )}
        style={{
          backgroundImage: type.includes('image') && url ? `url(${url})` : '',
        }}
      >
        {!type.includes('image') && (
          <FileIcon height={40} width={32} extension={getExtension(type)} />
        )}
      </div>
      <div
        className={cn(
          'border border-gray-200 rounded-b-xl flex flex-row items-center relative overflow-hidden p-4 gap-x-3',
          {
            'rounded-t-xl': layout === 'list',
          }
        )}
      >
        {typeof progress === 'number' && (
          <div
            className="h-full top-0 left-0 bg-gray-100 absolute -z-10"
            style={{ width: `${progress * 100}%` }}
          />
        )}
        <div
          className={cn({
            hidden: layout === 'grid',
            'h-10 w-10 bg-cover bg-center bg-no-repeat': type.includes('image'),
          })}
          style={{
            backgroundImage: type.includes('image') && url ? `url(${url})` : '',
          }}
        >
          {!type.includes('image') && (
            <FileIcon height={40} width={32} extension={getExtension(type)} />
          )}
        </div>
        <div className="flex-1 flex flex-col text-sm text-gray-700 font-medium">
          <div>{name}</div>
          <div className="font-normal text-gray-600">
            {bytesToHumanReadable(size)}
            {typeof progress === 'number'
              ? ` – ${removeUnnecessaryZeros(
                  (progress * 100).toFixed(0)
                )}% uploaded`
              : ''}
          </div>
        </div>
        {onDelete ? (
          <div>
            <Dropdown
              items={[
                { id: 'preview', label: 'Preview', icon: <EyeIcon /> },
                {
                  id: 'download',
                  label: 'Download',
                  icon: <DownloadIcon />,
                  as: 'a',
                  buttonProps: {
                    href: url,
                    download: name,
                    target: '_blank',
                  },
                },
                {
                  id: 'delete',
                  label: 'Delete',
                  icon: <TrashIcon />,
                  onClick: () => setDeleteModalOpenState(true),
                },
              ]}
            />
            <Modal
              isOpen={isDeleteModalOpen}
              onClose={() => setDeleteModalOpenState(false)}
              title="Delete file"
              description={`Are you sure you want to delete the "${name}" file? It cannot be restored.`}
            >
              <div className="flex flex-row gap-x-6">
                <button
                  className="flex-1 text-center px-4 py-[10px] rounded-lg border border-gray-200 font-semibold"
                  onClick={() => setDeleteModalOpenState(false)}
                >
                  Cancel
                </button>
                <button
                  className="flex-1 text-center px-4 py-[10px] rounded-lg bg-error-600 text-base-white font-semibold"
                  onClick={onDelete}
                >
                  Delete
                </button>
              </div>
            </Modal>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FileTile;
