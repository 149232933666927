import axios, { AxiosProgressEvent } from 'axios';
import { GenerateUploadUrlResponse } from '@distribute/shared/api-types/media-uploader';

export async function uploadFile(
  pageId: string,
  teamId: number,
  file: File,
  onUploadProgress: (event: AxiosProgressEvent) => void
) {
  const {
    data: { uploadUrlConfig, uploadedFileUrl },
  } = await axios.post<GenerateUploadUrlResponse>(
    'http://api.localhost/api/media/generate-upload-url',
    {
      fileMimeType: file.type,
      pathName: pageId,
      teamId,
    }
  );

  const formData = new FormData();

  Object.entries(uploadUrlConfig.fields).forEach(([key, value]) => {
    formData.append(key, value);
  });

  formData.append('file', file);

  await axios.post(uploadUrlConfig.url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });

  return uploadedFileUrl;
}
