import React from 'react';
import { Dialog } from '@headlessui/react';
import classNames from 'classnames';

type Props = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  dialogClassName?: string;
  panelWrapperStyles?: { [key: string]: string };
  disableCloseByOutsideClick?: boolean;
};

export const Modal: React.FC<Props> = ({
  title,
  description,
  children,
  isOpen,
  onClose,
  className,
  dialogClassName,
  panelWrapperStyles,
  disableCloseByOutsideClick = false,
}) => {
  return (
    <Dialog
      className={classNames('relative z-50 w-full', dialogClassName)}
      open={isOpen}
      onClose={() => {
        !disableCloseByOutsideClick && onClose();
      }}
    >
      <div
        style={panelWrapperStyles}
        className="fixed inset-0 flex items-center justify-center p-4 bg-bg-200"
      >
        <Dialog.Panel
          className={classNames(
            'sm:w-full w-100 shadow-xl rounded-xl bg-base-white p-6',
            className
          )}
        >
          {title && (
            <>
              <Dialog.Title className="flex justify-between items-center flex-shrink-0 text-gray-900 text-display-xs font-display font-medium w-full truncate mb-4">
                <span className="truncate max-w-full">{title}</span>
              </Dialog.Title>
              {description && (
                <Dialog.Description className="mb-8 text-gray-600 text-sm">
                  {description}
                </Dialog.Description>
              )}
            </>
          )}
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
