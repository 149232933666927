import Document from '@tiptap/extension-document';

import { Callout } from './extensions/Callout/Callout';
import { SnippetBlock } from './extensions/SnippetBlock/SnippetBlock';

import Color from '@tiptap/extension-color';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TextStyle from '@tiptap/extension-text-style';
import { FilePreviewExtension } from './extensions/FilePreview';
import { ImageExtension } from './extensions/Image';
import { ResizeableFigureExtension } from './extensions/ResizeableFigure';
import { CustomTable } from './extensions/Table';
import { CustomTableCell } from './extensions/TableCell';
import { VideoExtension } from './extensions/Video';
import { VimeoExtension } from './extensions/VimeoEmbed';
import { YoutubeExtension } from './extensions/YoutubeEmbed';

import Focus from '@tiptap/extension-focus';
import { Highlight } from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { AnyExtension } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Button } from './extensions/Button';
import { DropCursor } from './extensions/DropCursor';
import { UploadingPlaceholderPlugin } from './extensions/DropHandle/UploadingPlaceholderPlugin';
import { ExtendedHorizontalRule } from './extensions/HorizontalRule/ExtendedHorizontalRule';
import { CustomLink } from './extensions/Link';
import { ColumnExtension } from './extensions/MultipleColumns';
import { TodoList, TodoListItem } from './extensions/TodoList';
import {
  ToggleList,
  ToggleListItem,
  ToggleListItemContent,
} from './extensions/ToggleList';
import { UtilsExtension } from './extensions/utils';
import { EmbedContent, EmbedContentBlock } from './extensions/EmbedContent';
import { Iframe } from './extensions/Iframe';
import { CodeBlock, lowlight } from './extensions/CodeBlock';
import { CustomPlaceholder } from './extensions/Placeholder';
import { VideoRecordExtension } from './extensions/VideoRecord';
import { Timeline, TimelineItem } from './extensions/Timeline';
import { CustomTaskList, CustomTaskListItem } from './extensions/TaskList';
import { ContactCard } from './extensions/ContactCard';
import { UploadField } from './extensions/UploadField';

export const EditorDocument = Document.extend({
  content: 'block+',
});

export const getDefaultExtensions = (disableHistory?: boolean) =>
  [
    StarterKit.configure({
      document: false,
      heading: { levels: [2, 3, 4, 5, 6] },
      history: disableHistory ? false : undefined,
      dropcursor: {
        width: 0,
        class: 'hidden',
      },
    }),
    Underline,
    TextAlign.configure({
      types: ['heading', 'paragraph', 'listItem'],
    }),
    Focus,
    ExtendedHorizontalRule,
    UtilsExtension,
    Highlight.configure({
      multicolor: true,
      HTMLAttributes: {
        class: 'highlighted',
      },
    }),
  ] as AnyExtension[];

export const getMainEditorExtensions = (disableHistory?: boolean) => {
  return [
    ToggleList,
    ToggleListItem,
    ToggleListItemContent,
    ...getDefaultExtensions(disableHistory),
    CustomLink,
    Callout,
    SnippetBlock,
    EditorDocument,
    CustomTable,
    CustomTableCell,
    TableHeader,
    TableRow,
    CustomPlaceholder.configure({
      includeColumns: true,
      placeholder: ({ editor }) => {
        const decorations = UploadingPlaceholderPlugin.getState(
          editor.state
        ) as unknown as { children: [] } | undefined;
        if (decorations?.children?.length) {
          return '';
        }
        return "Press '/' for quick actions, ‘space’ for AI...";
      },
    }),
    FilePreviewExtension,
    ImageExtension,
    VideoExtension,
    VideoRecordExtension,
    ResizeableFigureExtension,
    YoutubeExtension.configure({ controls: true, allowFullscreen: true }),
    VimeoExtension.configure({ controls: true, allowFullscreen: true }),
    Color,
    TextStyle,
    ColumnExtension,
    DropCursor,
    TodoListItem.configure({
      nested: false,
    }),
    TodoList,
    Button,
    EmbedContentBlock,
    EmbedContent,
    Iframe,
    Timeline,
    TimelineItem,
    CustomTaskList,
    CustomTaskListItem,
    CodeBlock.configure({
      lowlight,
    }),
    ContactCard,
    UploadField,
  ];
};
