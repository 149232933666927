export function removeUnnecessaryZeros(value: string): string {
  if (!value.includes('.')) {
    return value;
  }

  const [integer, decimals] = value.split('.');

  if (Number(decimals) === 0) {
    return integer;
  }

  return value.replace(/(\.[0-9]*[1-9])0+$/, '$1');
}

const kilobyte = 1024;
const megabyte = kilobyte * 1024;
const gigabyte = megabyte * 1024;
const terabyte = gigabyte * 1024;

export function bytesToHumanReadable(
  bytes: number,
  maxDecimalsToDisplay = 2
): string {
  if (bytes < kilobyte) {
    return `${removeUnnecessaryZeros(bytes.toFixed(maxDecimalsToDisplay))} B`;
  }

  if (bytes < megabyte) {
    return `${removeUnnecessaryZeros(
      (bytes / kilobyte).toFixed(maxDecimalsToDisplay)
    )} KB`;
  }

  if (bytes < gigabyte) {
    return `${removeUnnecessaryZeros(
      (bytes / megabyte).toFixed(maxDecimalsToDisplay)
    )} MB`;
  }

  if (bytes < terabyte) {
    return `${removeUnnecessaryZeros(
      (bytes / gigabyte).toFixed(maxDecimalsToDisplay)
    )} GB`;
  }

  return `${removeUnnecessaryZeros(
    (bytes / terabyte).toFixed(maxDecimalsToDisplay)
  )} TB`;
}

export function getExtension(mime: string): string {
  return mime.split('/')[1];
}
