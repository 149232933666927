import { FC } from 'react';
import { GridIcon, ListIcon } from '../../icons';
import cn from 'classnames';

interface Props {
  layout: 'list' | 'grid';
  onSwitch(type: 'list' | 'grid'): void;
}

const LayoutSwitcher: FC<Props> = ({ layout, onSwitch }) => (
  <div className="flex flex-row flex-nowrap border border-gray-300 rounded-xl overflow-hidden">
    <button
      className={cn('px-3 py-2', { 'bg-gray-50': layout === 'list' })}
      onClick={() => onSwitch('list')}
      disabled={layout === 'list'}
    >
      <ListIcon />
    </button>
    <button
      className={cn('px-3 py-2 border-l border-l-gray-300', {
        'bg-gray-50': layout === 'grid',
      })}
      onClick={() => onSwitch('grid')}
      disabled={layout === 'grid'}
    >
      <GridIcon />
    </button>
  </div>
);

export default LayoutSwitcher;
