import { ReactNodeViewRenderer } from '@tiptap/react';
import { UploadFieldExt } from '@distribute/shared/generate-html';
import { UploadFieldNodeView } from './UploadField.renderer';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    uploadField: {
      insertUploadField: (pageId: string, teamId: number) => ReturnType;
    };
  }
}

export const UploadField = UploadFieldExt.extend({
  addCommands() {
    return {
      insertUploadField:
        (pageId: string, teamId: number) =>
        ({ commands }) => {
          return commands.insertContent({
            type: 'uploadField',
            attrs: {
              layout: 'list',
              pageId,
              serializedFiles: JSON.stringify([]),
              teamId,
            },
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(UploadFieldNodeView);
  },
});
