import { mergeAttributes, Node } from '@tiptap/react';

export const UploadFieldExt = Node.create({
  addAttributes() {
    return {
      layout: 'list',
      pageId: '',
      serializedFiles: JSON.stringify([]),
      teamId: '',
    };
  },
  name: 'uploadField',
  group: 'block',

  parseHTML() {
    return [{ tag: `div[data-type="${this.name}"]` }];
  },

  renderHTML({ node }) {
    if (typeof document !== 'undefined') {
      document.addEventListener('attr-changed', (event) => {
        const {
          detail: { attr, value },
        } = event as CustomEvent<{
          attr: 'serializedFiles' | 'layout';
          value: string;
        }>;

        // Call API to update the node
        console.log({ attr, value });
      });
    }

    return [
      'upload-field',
      mergeAttributes({
        layout: node.attrs.layout,
        'page-id': node.attrs.pageId,
        'serialized-files': node.attrs.serializedFiles,
        'team-id': node.attrs.teamId,
      }),
    ];
  },
});
