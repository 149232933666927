import cn from 'classnames';
import { FC, SVGProps } from 'react';

export const UploadIcon: FC<SVGProps<SVGSVGElement>> = ({
  className,
  height = 20,
  width = 20,
  ...props
}) => (
  <svg
    className={cn(className)}
    height={height}
    width={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.33335 13.5352C2.32837 12.8625 1.66669 11.7168 1.66669 10.4167C1.66669 8.46369 3.15961 6.85941 5.06647 6.68281C5.45653 4.31011 7.51689 2.5 10 2.5C12.4832 2.5 14.5435 4.31011 14.9336 6.68281C16.8404 6.85941 18.3334 8.46369 18.3334 10.4167C18.3334 11.7168 17.6717 12.8625 16.6667 13.5352M6.66669 13.3333L10 10M10 10L13.3334 13.3333M10 10V17.5"
      stroke="#737373"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const FileIcon: FC<SVGProps<SVGSVGElement> & { extension: string }> = ({
  className,
  extension,
  height = 20,
  width = 20,
  ...props
}) => {
  const first3 = extension.slice(0, 3);

  return (
    <i className="relative">
      <svg
        className={cn(className)}
        height={height}
        width={width}
        viewBox="0 0 32 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <mask
          id="mask0_6404_82278"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="40"
        >
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H20L32 12V36C32 38.2091 30.2091 40 28 40H4C1.79086 40 0 38.2091 0 36V4Z"
            fill="url(#paint0_linear_6404_82278)"
          />
        </mask>
        <g mask="url(#mask0_6404_82278)">
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H20L32 12V36C32 38.2091 30.2091 40 28 40H4C1.79086 40 0 38.2091 0 36V4Z"
            fill="#F5F5F5"
          />
        </g>
        <path d="M20 0L32 12H24C21.7909 12 20 10.2091 20 8V0Z" fill="#E5E5E5" />
        <defs>
          <linearGradient
            id="paint0_linear_6404_82278"
            x1="16"
            y1="0"
            x2="16"
            y2="40"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-opacity="0.4" />
            <stop offset="1" />
          </linearGradient>
        </defs>
      </svg>
      {extension && (
        <div
          className="absolute uppercase font-bold text-gray-700"
          style={{
            fontSize: 9,
            fontStyle: 'normal',
            transformOrigin: 'center',
            transform: 'translateX(30%) translateY(-130%)',
          }}
        >
          <span>{first3}</span>
          <span style={{ fontSize: 3 }}>...</span>
        </div>
      )}
    </i>
  );
};

export const ListIcon: FC<SVGProps<SVGSVGElement>> = ({
  className,
  height = 20,
  width = 20,
  ...props
}) => (
  <svg
    className={cn(className)}
    height={height}
    width={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="list">
      <path
        id="Icon"
        d="M17.5 9.9974L7.5 9.9974M17.5 4.9974L7.5 4.9974M17.5 14.9974L7.5 14.9974M4.16667 9.9974C4.16667 10.4576 3.79357 10.8307 3.33333 10.8307C2.8731 10.8307 2.5 10.4576 2.5 9.9974C2.5 9.53716 2.8731 9.16406 3.33333 9.16406C3.79357 9.16406 4.16667 9.53716 4.16667 9.9974ZM4.16667 4.9974C4.16667 5.45763 3.79357 5.83073 3.33333 5.83073C2.8731 5.83073 2.5 5.45763 2.5 4.9974C2.5 4.53716 2.8731 4.16406 3.33333 4.16406C3.79357 4.16406 4.16667 4.53716 4.16667 4.9974ZM4.16667 14.9974C4.16667 15.4576 3.79357 15.8307 3.33333 15.8307C2.8731 15.8307 2.5 15.4576 2.5 14.9974C2.5 14.5372 2.8731 14.1641 3.33333 14.1641C3.79357 14.1641 4.16667 14.5372 4.16667 14.9974Z"
        stroke="#424242"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export const GridIcon: FC<SVGProps<SVGSVGElement>> = ({
  className,
  height = 20,
  width = 20,
  ...props
}) => (
  <svg
    className={cn(className)}
    height={height}
    width={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="grid-01">
      <g id="Icon">
        <path
          d="M7 2.5H3.83333C3.36662 2.5 3.13327 2.5 2.95501 2.59083C2.79821 2.67072 2.67072 2.79821 2.59083 2.95501C2.5 3.13327 2.5 3.36662 2.5 3.83333V7C2.5 7.46671 2.5 7.70007 2.59083 7.87833C2.67072 8.03513 2.79821 8.16261 2.95501 8.24251C3.13327 8.33333 3.36662 8.33333 3.83333 8.33333H7C7.46671 8.33333 7.70007 8.33333 7.87833 8.24251C8.03513 8.16261 8.16261 8.03513 8.24251 7.87833C8.33333 7.70007 8.33333 7.46671 8.33333 7V3.83333C8.33333 3.36662 8.33333 3.13327 8.24251 2.95501C8.16261 2.79821 8.03513 2.67072 7.87833 2.59083C7.70007 2.5 7.46671 2.5 7 2.5Z"
          stroke="#424242"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.1667 2.5H13C12.5333 2.5 12.2999 2.5 12.1217 2.59083C11.9649 2.67072 11.8374 2.79821 11.7575 2.95501C11.6667 3.13327 11.6667 3.36662 11.6667 3.83333V7C11.6667 7.46671 11.6667 7.70007 11.7575 7.87833C11.8374 8.03513 11.9649 8.16261 12.1217 8.24251C12.2999 8.33333 12.5333 8.33333 13 8.33333H16.1667C16.6334 8.33333 16.8667 8.33333 17.045 8.24251C17.2018 8.16261 17.3293 8.03513 17.4092 7.87833C17.5 7.70007 17.5 7.46671 17.5 7V3.83333C17.5 3.36662 17.5 3.13327 17.4092 2.95501C17.3293 2.79821 17.2018 2.67072 17.045 2.59083C16.8667 2.5 16.6334 2.5 16.1667 2.5Z"
          stroke="#424242"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.1667 11.6667H13C12.5333 11.6667 12.2999 11.6667 12.1217 11.7575C11.9649 11.8374 11.8374 11.9649 11.7575 12.1217C11.6667 12.2999 11.6667 12.5333 11.6667 13V16.1667C11.6667 16.6334 11.6667 16.8667 11.7575 17.045C11.8374 17.2018 11.9649 17.3293 12.1217 17.4092C12.2999 17.5 12.5333 17.5 13 17.5H16.1667C16.6334 17.5 16.8667 17.5 17.045 17.4092C17.2018 17.3293 17.3293 17.2018 17.4092 17.045C17.5 16.8667 17.5 16.6334 17.5 16.1667V13C17.5 12.5333 17.5 12.2999 17.4092 12.1217C17.3293 11.9649 17.2018 11.8374 17.045 11.7575C16.8667 11.6667 16.6334 11.6667 16.1667 11.6667Z"
          stroke="#424242"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7 11.6667H3.83333C3.36662 11.6667 3.13327 11.6667 2.95501 11.7575C2.79821 11.8374 2.67072 11.9649 2.59083 12.1217C2.5 12.2999 2.5 12.5333 2.5 13V16.1667C2.5 16.6334 2.5 16.8667 2.59083 17.045C2.67072 17.2018 2.79821 17.3293 2.95501 17.4092C3.13327 17.5 3.36662 17.5 3.83333 17.5H7C7.46671 17.5 7.70007 17.5 7.87833 17.4092C8.03513 17.3293 8.16261 17.2018 8.24251 17.045C8.33333 16.8667 8.33333 16.6334 8.33333 16.1667V13C8.33333 12.5333 8.33333 12.2999 8.24251 12.1217C8.16261 11.9649 8.03513 11.8374 7.87833 11.7575C7.70007 11.6667 7.46671 11.6667 7 11.6667Z"
          stroke="#424242"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
  </svg>
);

export const DotsVerticalIcon: FC<SVGProps<SVGSVGElement>> = ({
  className,
  height = 20,
  width = 20,
  ...props
}) => (
  <svg
    className={cn(className)}
    height={height}
    width={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 10.832C10.4603 10.832 10.8334 10.4589 10.8334 9.9987C10.8334 9.53846 10.4603 9.16536 10 9.16536C9.53978 9.16536 9.16669 9.53846 9.16669 9.9987C9.16669 10.4589 9.53978 10.832 10 10.832Z"
      stroke="#525252"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 4.9987C10.4603 4.9987 10.8334 4.6256 10.8334 4.16536C10.8334 3.70513 10.4603 3.33203 10 3.33203C9.53978 3.33203 9.16669 3.70513 9.16669 4.16536C9.16669 4.6256 9.53978 4.9987 10 4.9987Z"
      stroke="#525252"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 16.6654C10.4603 16.6654 10.8334 16.2923 10.8334 15.832C10.8334 15.3718 10.4603 14.9987 10 14.9987C9.53978 14.9987 9.16669 15.3718 9.16669 15.832C9.16669 16.2923 9.53978 16.6654 10 16.6654Z"
      stroke="#525252"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const TrashIcon: FC<SVGProps<SVGSVGElement>> = ({
  className,
  height = 20,
  width = 20,
  ...props
}) => (
  <svg
    className={cn(className)}
    height={height}
    width={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="trash-01">
      <path
        id="Icon"
        d="M10.6667 4.0026V3.46927C10.6667 2.72253 10.6667 2.34917 10.5213 2.06395C10.3935 1.81307 10.1895 1.60909 9.93865 1.48126C9.65344 1.33594 9.28007 1.33594 8.53333 1.33594H7.46667C6.71993 1.33594 6.34656 1.33594 6.06135 1.48126C5.81046 1.60909 5.60649 1.81307 5.47866 2.06395C5.33333 2.34917 5.33333 2.72253 5.33333 3.46927V4.0026M6.66667 7.66927V11.0026M9.33333 7.66927V11.0026M2 4.0026H14M12.6667 4.0026V11.4693C12.6667 12.5894 12.6667 13.1494 12.4487 13.5773C12.2569 13.9536 11.951 14.2595 11.5746 14.4513C11.1468 14.6693 10.5868 14.6693 9.46667 14.6693H6.53333C5.41323 14.6693 4.85318 14.6693 4.42535 14.4513C4.04903 14.2595 3.74307 13.9536 3.55132 13.5773C3.33333 13.1494 3.33333 12.5894 3.33333 11.4693V4.0026"
        stroke="#525252"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);
export const DownloadIcon: FC<SVGProps<SVGSVGElement>> = ({
  className,
  height = 20,
  width = 20,
  ...props
}) => (
  <svg
    className={cn(className)}
    height={height}
    width={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="download-02">
      <path
        id="Icon"
        d="M14 14H2M12 7.33333L8 11.3333M8 11.3333L4 7.33333M8 11.3333V2"
        stroke="#525252"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);
export const EyeIcon: FC<SVGProps<SVGSVGElement>> = ({
  className,
  height = 20,
  width = 20,
  ...props
}) => (
  <svg
    className={cn(className)}
    height={height}
    width={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="eye">
      <g id="Icon">
        <path
          d="M1.61342 8.47806C1.52262 8.3343 1.47723 8.26242 1.45182 8.15155C1.43273 8.06827 1.43273 7.93694 1.45182 7.85366C1.47723 7.74279 1.52262 7.67091 1.61341 7.52715C2.36369 6.33916 4.59693 3.33594 8.00027 3.33594C11.4036 3.33594 13.6369 6.33916 14.3871 7.52715C14.4779 7.67091 14.5233 7.74279 14.5487 7.85366C14.5678 7.93694 14.5678 8.06827 14.5487 8.15155C14.5233 8.26242 14.4779 8.3343 14.3871 8.47806C13.6369 9.66604 11.4036 12.6693 8.00027 12.6693C4.59693 12.6693 2.36369 9.66604 1.61342 8.47806Z"
          stroke="#525252"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.00027 10.0026C9.10484 10.0026 10.0003 9.10717 10.0003 8.0026C10.0003 6.89803 9.10484 6.0026 8.00027 6.0026C6.8957 6.0026 6.00027 6.89803 6.00027 8.0026C6.00027 9.10717 6.8957 10.0026 8.00027 10.0026Z"
          stroke="#525252"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
  </svg>
);
