import { ChangeEvent, FC, useCallback, useEffect, useId, useRef } from 'react';
import { UploadIcon } from '../../icons';
import { bytesToHumanReadable } from '../../lib/parsers';
import cn from 'classnames';

interface Props {
  error?: string;
  maxSizeInBytes?: number;
  onDrop: (files: File[]) => void;
}

const DropArea: FC<Props> = ({
  error,
  maxSizeInBytes = 100 * 1024 * 1024,
  onDrop,
}) => {
  const labelRef = useRef<HTMLLabelElement>(null);
  const inputId = useId();

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        onDrop(Array.from(event.target.files));
      }
    },
    [onDrop]
  );

  useEffect(() => {
    const labelElement = labelRef.current;

    function dragOverListener(e: DragEvent) {
      e.preventDefault();
      labelRef.current?.classList.add('bg-primary-50');
    }

    function dragLeaveListener(e: DragEvent) {
      e.preventDefault();
      labelRef.current?.classList.remove('bg-gray-50');
    }

    function dropListener(e: DragEvent) {
      e.preventDefault();
      labelRef.current?.classList.remove('bg-gray-50');
      const files = Array.from(e.dataTransfer?.files || []);
      onDrop(files);
    }

    labelElement?.addEventListener('dragover', dragOverListener);
    labelElement?.addEventListener('dragleave', dragLeaveListener);
    labelElement?.addEventListener('drop', dropListener);

    return () => {
      labelElement?.removeEventListener('dragover', dragOverListener);
      labelElement?.removeEventListener('dragleave', dragLeaveListener);
      labelElement?.removeEventListener('drop', dropListener);
    };
  }, [onDrop]);

  return (
    <>
      <label
        className={cn(
          'bg-gray-50 rounded-xl border-1.5 relative font-medium text-sm text-gray-600 w-full flex flex-col p-6 gap-y-2 select-none items-center',
          {
            'border-solid border-error-600': error,
            'border-dashed border-base-black-20': !error,
          }
        )}
        htmlFor={inputId}
        ref={labelRef}
      >
        <UploadIcon className="stroke-gray-500" />
        <div className="text-center">
          Drag and drop your files here <br />{' '}
          <span className="font-semibold text-primary-700">
            browse your files
          </span>{' '}
          or{' '}
          <button className="font-semibold text-primary-700 underline">
            embed a link
          </button>
        </div>
        <div className="font-normal text-xs text-gray-500">
          Max file size {bytesToHumanReadable(maxSizeInBytes)}
        </div>
      </label>
      <input
        id={inputId}
        type="file"
        onChange={onChange}
        className="appearance-none absolute opacity-0 pointer-events-none left-0 top-0"
      />
      {error && <div className="text-error-600 mt-2 text-sm">{error}</div>}
    </>
  );
};

export default DropArea;
