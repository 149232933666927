import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import {
  UploadField,
  UploadFieldAttrs,
} from '@distribute/frontend/ui/upload-field';
import { useCallback } from 'react';
import { NodeType } from '@tiptap/pm/model';

export type UploadFieldItemNodeType = NodeType & {
  attrs: UploadFieldAttrs;
};

export type UploadFieldItemNodeViewProps = NodeViewProps & {
  node: UploadFieldItemNodeType;
};

export const UploadFieldNodeView = ({
  node,
  editor,
  getPos,
}: UploadFieldItemNodeViewProps) => {
  const onAttrsChange = useCallback(
    (attr: 'serializedFiles' | 'layout', value: string) => {
      editor
        .chain()
        .focus()
        .setNodeSelection(getPos())
        .updateAttributes('uploadField', { [attr]: value })
        .run();
    },
    [editor, getPos]
  );

  return (
    <NodeViewWrapper className="!border-none">
      <div className="hidden grid-cols-2 no-underline px-4 py-[10px] mb-4 w-10" />
      <UploadField onAttrChanges={onAttrsChange} {...node.attrs} />
    </NodeViewWrapper>
  );
};
