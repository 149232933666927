/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  FC,
  ReactNode,
  useState,
} from 'react';
import cn from 'classnames';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { DotsVerticalIcon } from '../../icons';

export type DropdownItem = {
  id: string;
  label: ReactNode;
  disabled?: boolean;
  isSeparatedFromTop?: boolean;
  icon?: ReactNode;
  onClick?: () => void;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
} & (
  | {
      as?: 'button';
      buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
    }
  | {
      as?: 'a';
      buttonProps?: AnchorHTMLAttributes<HTMLAnchorElement>;
    }
);

interface Props {
  items: DropdownItem[];
  triggerContent?: ReactNode;
  align?: 'end' | 'start' | 'center';
  onItemChange?: (itemId: string) => void;
}

const Dropdown: FC<Props> = ({
  triggerContent,
  items,
  align = 'end',
  onItemChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownMenu.Root
      open={isOpen}
      onOpenChange={(newState) => setIsOpen(newState)}
    >
      <DropdownMenu.Trigger className="flex">
        {triggerContent ?? <DotsVerticalIcon />}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        align={align}
        sideOffset={4}
        className="border rounded-lg bg-base-white divide-solid border-gray-200 focus:outline-none overflow-hidden py-1 z-10 mt-3"
      >
        <>
          {items.map((item) => (
            <DropdownMenu.Item
              key={item.id}
              onSelect={() => {
                item.onClick?.();
                onItemChange?.(item.id);
              }}
              className="outline-none"
            >
              {item.isSeparatedFromTop && (
                <div className="h-0.25 w-full bg-gray-200 my-1" />
              )}
              <div className="px-1.5 py-0.5">
                {item.as === 'a' ? (
                  <a
                    className={cn(
                      `text-left whitespace-nowrap w-full p-2.5 transition-colors focus:outline-none rounded-md
                        flex flex-row items-center hover:cursor-pointer text-gray-700 text-sm font-medium gap-x-2 !no-underline`,
                      {
                        'hover:bg-gray-50 active:bg-gray-100': !item.disabled,
                      }
                    )}
                    href="#"
                    {...(item.buttonProps as AnchorHTMLAttributes<HTMLAnchorElement>)}
                  >
                    {item.icon}
                    {item.label}
                  </a>
                ) : (
                  <button
                    type="button"
                    className={cn(
                      `text-left whitespace-nowrap w-full p-2.5 transition-colors focus:outline-none rounded-md
                        flex flex-row items-center hover:cursor-pointer text-gray-700 text-sm font-medium gap-x-2`,
                      {
                        'hover:bg-gray-50 active:bg-gray-100': !item.disabled,
                      }
                    )}
                    {...item.buttonProps}
                  >
                    {item.icon}
                    {item.label}
                  </button>
                )}
              </div>
            </DropdownMenu.Item>
          ))}
        </>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default Dropdown;
