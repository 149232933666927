import { UploadedFile } from '../types';

export function serializeFiles(fileList: UploadedFile[]) {
  try {
    return JSON.stringify(fileList);
  } catch (error) {
    return JSON.stringify([]);
  }
}

export function deserializeFiles(serializedFiles: string): UploadedFile[] {
  try {
    return JSON.parse(serializedFiles);
  } catch (error) {
    return [];
  }
}
